<template>
  <div class="card card-body shadow-sm">
    <app-basic-table
      ref="basic_table"
      :table-name="$t('users.user list')"
      :filters.sync="filters"
      :setting-columns="columns"
      :endpoint-list="ENDPOINT.CUSTOMER_VITAL_LIST"
      @resetAllSearch="onResetAllSearch"
      @searched="onSearch"
    >
      <template v-slot:filters>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group">
              <label>{{ filters.apartment_id_equal.label }}</label>
              <app-select :name="filters.apartment_id_equal.name"
                          input-style="normal"
                          v-model="filters.apartment_id_equal.value"
                          :options-data="meta.apartments"

              />
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group">
              <label>{{ filters.name_kana_like.label }}</label>
              <app-input :name="filters.name_kana_like.name" input-style="normal"
                         v-model="filters.name_kana_like.value"
              />
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group">
              <label>{{ filters.recorded_at_between.label }}</label>


              <app-date-range-picker
                :name="filters.recorded_at_between.name"
                v-model="filters.recorded_at_between.value"
                timePicker24Hour="false"
                format="YYYY-MM-DD"
                @input="filters.recorded_at_between.value = $event"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-slot:table-menu-right>
        <button @click="handleBtnCreateClick()" class="btn btn-warning">
          {{ $t("common.create") }}
        </button>
      </template>
      <template v-slot:body-cell-edit="props">
        <td class="app-align-middle text-center app-p-none">
          <button
            @click="handleBtnUpdateClick(props.row)"
            class="btn btn-primary"
          >
            {{ $t("common.edit") }}
          </button>
        </td>
      </template>
      <template v-slot:body-cell-delete="props">
        <td class="app-align-middle text-center app-p-none">
          <button
            @click="handleBtnDeleteClick(props.row)"
            class="btn btn-warning"
          >
            {{ $t("common.delete") }}
          </button>
        </td>
      </template>
<!--      <template v-slot:body-cell-apartment_id="props">-->
<!--        <td class="app-align-middle">-->
<!--          <a :href="getApartmentEditUrl(props.row.apartment_id)" target="_blank">-->
<!--            <p :data-original-title="getApartmentName(props.row.apartment_id)" class="app-table-p app-cell-tooltip mb-0">-->
<!--              {{ getApartmentName(props.row.apartment_id) }}-->
<!--            </p>-->
<!--          </a>-->
<!--        </td>-->
<!--      </template>-->


      <template v-slot:body-cell-apartment_id="props">
        <td class="app-align-middle">
          <p :data-original-title="getApartmentName(props.row.apartment_id)" style="color: #007bff;" @click="handlerApartmentNameClick(props.row)"  class="apartment_name app-table-p app-cell-tooltip mb-0">
            {{getApartmentName(props.row.apartment_id)}}
          </p>
        </td>
      </template>

      <template v-slot:body-cell-customer_id="props">
        <td class="app-align-middle">
          <p :data-original-title="getCustomerName(props.row.customer_id)" class="app-table-p app-cell-tooltip mb-0" >
            {{ getCustomerName(props.row.customer_id) }}
          </p>
        </td>
      </template>
      <template v-slot:body-cell-name_kana="props">
        <td class="app-align-middle">
          <p :data-original-title="getCustomerNameKana(props.row.customer_id)" class="app-table-p app-cell-tooltip mb-0" >
            {{ getCustomerNameKana(props.row.customer_id) }}
          </p>
        </td>
      </template>
<!--      <template v-slot:body-cell-recorded_date_at="props">-->
<!--        <td class="app-align-middle text-center">-->
<!--          <p>{{ moment(props.row.recorded_at).format('YYYY-MM-DD') }}</p>-->
<!--        </td>-->
<!--      </template>-->
<!--      <template v-slot:body-cell-recorded_time_at="props">-->
<!--        <td class="app-align-middle text-center">-->
<!--          <p>{{ moment(props.row.recorded_at).format('hh:mm') }}</p>-->
<!--        </td>-->
<!--      </template>-->
    </app-basic-table>
  </div>
</template>
<script>
import AppBasicTable from "@components/_common/list/AppBasicTable";
import {ROLES, BASIC_TABLE} from "@constants";

export default {
  name: "ListAllCustomerVital",

  components: {
    "app-basic-table": AppBasicTable,
  },

  data() {
    return {
      idCache: null,
      paramGetList: {},
      filters: {
        name_kana_like: {
          name: "name_kana",
          condition: "like",
          label: this.$t("customer-vitals.name_kana_list_search")
        },
        apartment_id_equal: {
          name: "apartment_id",
          condition: "equal",
          label: this.$t("customers.apartment")
        },
        recorded_at_between: {
          name: "recorded_at",
          condition: "between",
          label: this.$t("customer-vitals.recorded_at")
        },
      },
      columns: [
        {name: "recorded_date_at", label: this.$t("customer-vitals.recorded_at"), sortable: true, textAlign: 'text-center'},
        {name: "recorded_time_at", label: this.$t("customer-vitals.recorded_time_at"), textAlign: 'text-center'},
        {name: "customer_id", label: this.$t("common.customer_name"), sortable: true},
        {name: "name_kana", label: this.$t("common.name_kana"), sortable: true},
        {name: "apartment_id", label: this.$t("customers.apartment"), sortable: true},
        {name: "temperature", label: this.$t("customer-vitals.temperature"), sortable: true, textAlign: 'text-center'},
        {name: "pressure_high", label: this.$t("customer-vitals.pressure_high"), sortable: true, textAlign: 'text-center'},
        {name: "pressure_low", label: this.$t("customer-vitals.pressure_low"), sortable: true, textAlign: 'text-center'},
        {name: "situation", label: this.$t("customer-vitals.situation"), textAlign: 'text-center'},
        {name: "edit", label: this.$t("common.edit"), textAlign: 'text-center'},
      ],
      meta: {
      }
    };
  },

  mounted: function () {
    this.$request.get(this.ENDPOINT.UI_HELPER_CUSTOMER_VITAL).then(res => {
      let apartments = _.cloneDeep(res.data.apartments);
      apartments.unshift({
        id: 'all',
        name: this.$t('common.all'),
      });
      this.meta = {
        apartments: apartments,
        customers: res.data.customers,
      }
    })
  },

  methods: {
    handleBtnCreateClick() {
      localStorage.setItem('vital_search_url', window.location.href);
      this.$router.push({name: this.ROUTES.ADMIN.CUSTOMER_VITAL_CREATE})
    },

    handleBtnUpdateClick(entry) {
      localStorage.setItem('vital_search_url', window.location.href);
      this.$router.push({name: this.ROUTES.ADMIN.CUSTOMER_VITAL_EDIT,params:  {id: entry.id}})
    },

    getStatusText(status)
    {
      return this.meta.status[status]
    },

    getApartmentName(apartmentId)
    {
      let apartment = this.meta.apartments.find(({id}) => id === apartmentId);
      if (apartment == undefined) {
        return '';
      }
      return apartment.name;
    },
    handlerApartmentNameClick(entry) {
      this.$router.push({name: this.ROUTES.ADMIN.APARTMENT_EDIT, params: {id: entry.apartment_id}})
    },
    getCustomerNameKana(customerId)
    {
      let entry = this.meta.customers.find(({id}) => id === customerId);
      if (entry == undefined) {
        return '';
      }
      return entry.name_kana;
    },
    getCustomerName(customerId)
    {
      let entry = this.meta.customers.find(({id}) => id === customerId);
      if (entry == undefined) {
        return '';
      }
      return entry.name;
    },

    getApartmentEditUrl(apartmentId)
    {
      let props = this.$router.resolve({
        name: this.ROUTES.ADMIN.APARTMENT_EDIT,
        params: { id: apartmentId },
      });

      return props.href;
    },

    getActiveText(active) {
      if (active) {
        return this.$t("common.active");
      } else {
        return this.$t("common.deactive");
      }
    },
    onResetAllSearch() {
      this.$router.push({name: this.$route.name,      query : {
          'filters.apartment_id.equal' : 'all',
          'filters.recorded_at.between' : moment().format('YYYY-MM-DD'),
        }})
    },
    onSearch() {
      // this.getTourSelectName();
    },
  },
};
</script>
